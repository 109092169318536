$white: #FFFFFF;
$dark-blue: #274B8a;
$light-blue: #DCF1FA;
$orange: #F4784D;

body{
  background-color: $white;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: $dark-blue;
}

header{
  background-color: $light-blue;
}

footer{
  background-color: $light-blue;
}

main{
  background-color: $light-blue;
}

h1{
  margin-bottom: 24px;
  font-size: 48px;
  font-weight: 700;
  line-height: 1.3;
}

h2{
  margin-bottom: 24px;
  font-size: 48px;
  font-weight: 700;
  line-height: 1.3;
}

h3{
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.3;
}

p{
  margin: 16px 0;
}

strong{
  font-weight: 700;
}

ul{
  margin-bottom: 24px;
  list-style: circle;
}

li{
  margin-left: 16px;
}

a{
  color: $dark-blue;
  text-decoration: underline;
  cursor: pointer;
  transition: all .25s ease-out;
  
  &:hover{
    color: $orange;
  }
}

.button{
  color: $dark-blue;
  text-decoration: underline;
  font-style: italic;
  transition: all 0.25s ease-out;

  &:hover {
    color: $orange;
  }
}

.arrow{
  margin-left: 4px;
  fill: $dark-blue;
  transition: all 0.25s ease-out;

  &:hover{
    color: $orange;
  }
}

.button:hover .arrow{
  margin-left: 16px;
  fill: $orange;
}

.flex-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container{
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px;
}

.logo{
  width: 120px;
  height: 120px;
  background-image: url("/assets/images/katreyyy-site-head-1.png");
  background-repeat: no-repeat;
  background-size: contain;
  border: none;

  &:hover{
    background-image: url("/assets/images/katreyyy-site-head-2.png");
  }

  &::after{
    content:"";
  }
}

.socials{
  display: flex;
  align-items: center;
  
  img{
    display: block;
  }

  a{
    margin-right: 32px;
    padding: 8px;
    border: none;
    border-radius: 8px;

    &:hover{ 
      transition: all .25s ease-out;
      background-color: $dark-blue;
    }

    &::after{
      content: none;
    }

    &:last-child{
      margin-right: 0;
    }
  }
}

.title{
  margin-bottom: 32px;
  text-align: center;
}

.showcase{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;
}

.box{
  margin-bottom: 1.5rem;
 	width: 30%;
 	background-color: $white;
  border-radius: 8px;
  overflow: hidden;

  img{
    width: 100%;
  }

  .card-text{
    padding: 24px;
  }

  .box-link{
    color: $dark-blue;
    text-decoration: none;
  }
}

.cta{
  text-align: center;
}

.about{
  margin: 32px 0;
  .text-box{
    margin: 0 auto;
    max-width: 520px;
  }
}

.contact{
  margin: 32px 0;

  .contact-text{
    padding: 64px;
    position: relative;
    background-color: $light-blue;
    border-radius: 8px;

    img{
      position: absolute;
      top: -94px;
      left: -16px;
    }
  }
}

/*Project*/

.project{
  p{
    margin: 16px auto;
    padding: 16px 0;
    max-width: 520px;
  }

  .img-only{
    max-width: 100%;
    text-align: center;
  }
}

.project-footer{
  padding-top: 16px;
  text-align: right;
  border-top: 2px solid $light-blue;
}

/*404*/

.four-o-four{
  height: 100vh;
  padding-top: 64px;

  .four-o-four-text{
    padding: 120px;
    position: relative;
    background-color: $white;
    border-radius: 8px;

    img{
      position: absolute;
      top: -94px;
      left: -16px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .showcase, .img-row{
    flex-direction: column;
    margin-bottom: 32px;
  }

  .box, .row-item{
    width: 100%;
    margin-bottom: 24px;
  }

  .contact{
    .contact-text{
      padding: 32px;

      img{
        width: 16%;
        top: -60px;
      }
    }
  }

  .four-o-four{
    .four-o-four-text{
      padding: 32px;

      img{
        width: 16%;
        top: -60px;
      }
    }
  }
}

@media only screen and (max-width: 580px) {
  h1{
    font-size: 32px;
  }

  h2{
    font-size: 24px;
  }

  .logo{
    width: 60px;
    height: 60px;
  }

  .socials a{
    margin-right: 8px;
  }

  .contact{
    margin: 0;
    .contact-text{
      img{
        display: none;
      }
    }
  }

  .four-o-four{
    .four-o-four-text{
      img{
        display: none;
      }
    }
  }
}